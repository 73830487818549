<template>
  <div id="app">
    <v-app light>
      <v-toolbar color="white">
        <a href="https://thalesgroup.com">
          <v-img src="../assets/Thales_LOGO_RGB.jpg" max-width="150" />
        </a>
      </v-toolbar>
      <v-main>
        <section>
          <v-parallax src="../assets/background_2.png" height="500">
            <v-layout column align-center justify-center class="white--text">
              <img src="../assets/iSeriesReader_grey.png" alt="Vuetify.js" height="100" />
              <h1 class="white--text mb-2 display-1 text-center mt-6">iSeries Document Readers</h1>
              <div class="subheading mb-4 text-center">ID Verification Anywhere</div>
              <v-btn
                class="mt-6"
                color="blue lighten-2"
                dark
                large
                href="https://www.thalesgroup.com/en/markets/digital-identity-and-security/government/document-readers"
              >Learn More</v-btn>
            </v-layout>
          </v-parallax>
        </section>

        <section>
          <v-layout column wrap class="my-12" align-center>
            <v-flex xs12 sm4 class="my-4">
              <div class="text-center">
                <h2 class="headline">iSeries Applications</h2>
                <span class="subheading">Demo Applications and Provisioning Applications</span>
              </div>
            </v-flex>
          </v-layout>
        </section>

        <section>
          <v-parallax src="../assets/passport_3.jpg" height="500">
            <v-container fluid>
              <v-row dense>
                <v-card class="mx-auto" max-width="450" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">Demo Apps</v-list-item-title>
                      <v-list-item-subtitle>For testing the functionality of our Readers</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80">
                      <v-img src="@/assets/demo-logo.png"></v-img>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.gemalto.docreader.demo"
                    >
                      <v-img
                        class="center"
                        contain
                        max-width="150"
                        src="@/assets/google-play-badge.svg"
                      ></v-img>
                    </a>
                    <a href="https://apps.apple.com/us/app/docreaderex/id1427600627">
                      <v-img contain max-width="120" src="@/assets/app-store-badge.svg"></v-img>
                    </a>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" dark href="http://demo.iseriesreader.com/app2/">
                      Web App
                      <v-icon>mdi-vuejs</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-card-actions>
                    <h5>
                      *HTTPS connections to our readers require trusting
                      <a href="../PageReaderCA.zip" download>Root Certificates</a>
                    </h5>
                  </v-card-actions>

                  <v-divider light></v-divider>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">Provisioning Apps</v-list-item-title>
                      <v-list-item-subtitle>For easy deployment of your Readers</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80">
                      <v-img src="@/assets/provisioner-logo.png"></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!--<a href="https://play.google.com/store/apps/details?id=com.gemalto.provisioner.iSeriesProvisioner">-->
                    <a href="https://play.google.com/store/apps/details?id=com.thalesgroup.iseriesprovisionerdirect" >
                      <v-img contain max-width="150" src="@/assets/google-play-badge.svg"></v-img>
                    </a>
                    <a href="https://apps.apple.com/us/app/id1504106478/">
                      <v-img contain max-width="120" src="@/assets/app-store-badge.svg"></v-img>
                    </a>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-parallax>
        </section>

        <section>
          <v-layout column wrap class="my-12" align-center>
            <v-flex xs12 sm4 class="my-4">
              <div class="text-center">
                <h2 class="headline">The Future of Identity Verification</h2>
                <span class="subheading">Intelligent Document Reading</span>
              </div>
            </v-flex>
            <v-flex xs12>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 md4>
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-wifi</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-center">Intelligently Connected</div>
                      </v-card-title>
                      <v-card-text>Included with an embedded Arm® processor running Linux® allowing for seamless connectivity from any device over the network. Using web style encrypted JSON messaging to simplify development, deployment, and maintenance.</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-ruler-square</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline">Intuitive Design</div>
                      </v-card-title>
                      <v-card-text>
                        The design is based on detailed analysis and research.
                        Introduced with a new "landing lights" LED feedback and hold down clips it naturally encourages the correct placement and use, maximizing first-time read rate for faster customer processing.
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-cogs</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-center">Integrate Easily</div>
                      </v-card-title>
                      <v-card-text>iSeries Readers provide flexible install options. With the ability for Legacy USB Connectivity you can easily adapt the iSeries to your current system, while being ready for the next generation of identity verification and document reading solutions.</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </section>

        <section>
          <v-parallax src="../assets/SoftwareM.jpg" height="500">
            <v-container fluid>
              <v-row dense>
                <div class="transparent">
                  <v-tabs v-model="tab" grow center-active>
                    <v-tab v-for="reader in readers" :key="reader">{{ reader }}</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-card light class="mx-auto" max-width="400">
                        <v-img width="300" height="300" src="../assets/AT10Ki w_hood.jpg"></v-img>

                        <v-card-title>AT10Ki</v-card-title>

                        <v-card-subtitle>Our Flagship iSeries Reader</v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                            text
                            href="https://www.thalesgroup.com/en/markets/digital-identity-and-security/government/document-readers/at10ki"
                          >More</v-btn>

                          <v-btn
                            color="blue"
                            text
                            href="https://www.thalesgroup.com/sites/default/files/database/document/2021-08/gov-DS-intelligent-doc-reader-AT10Ki.pdf"
                          >Technical Data Sheet</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>

                    <v-tab-item>
                      <v-card light class="mx-auto" max-width="400">
                        <v-img width="300" height="300" src="../assets/AT10ki-OEM.jpg"></v-img>

                        <v-card-title>AT10Ki-OEM</v-card-title>

                        <v-card-subtitle>Perfect for Self-Service Kiosks</v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                            text
                            href="https://www.thalesgroup.com/en/markets/digital-identity-and-security/government/document-readers/at10ki-oem"
                          >More</v-btn>

                          <v-btn
                            color="blue"
                            text
                            href="https://www.thalesgroup.com/sites/default/files/database/document/2020-08/gov-DS-intelligent-OEM-doc-reader-AT10Ki.pdf"
                          >Technical Data Sheet</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card light class="mx-auto" max-width="400">
                        <v-img
                          width="300"
                          height="300"
                          src="../assets/gov-readers-CR5400i-with-card_0.jpg"
                        ></v-img>

                        <v-card-title>CR5400i</v-card-title>

                        <v-card-subtitle>Made for ID Cards</v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                            text
                            href="https://www.thalesgroup.com/en/markets/digital-identity-and-security/government/document-readers/cr5400i"
                          >More</v-btn>

                          <v-btn
                            color="blue"
                            text
                            href="https://www.thalesgroup.com/sites/default/files/database/document/2020-09/gov-reader-CR5400i-intelligent-double-sided-id-card-ds.pdf"
                          >Technical Data Sheet</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-row>
            </v-container>
          </v-parallax>
        </section>

        <section>
          <v-container grid-list-xl>
            <v-layout row wrap justify-center class="my-12">
              <v-flex xs12 sm4>
                <v-card flat class="transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Our Team</div>
                  </v-card-title>
                  <v-card-text>
                    The Document Reader Solutions team is now a part of
                    <a
                      href="https://www.thalesgroup.com/en/markets/digital-identity-and-security"
                    >Thales Group's - Digital Identity and Security</a>. We provide a range of identification verification and authentication solutions that help prevent document and identity fraud. Our wide array of document readers provide solutions that meet the changing security demands of companies and agencies.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm4 offset-sm1>
                <v-card flat class="transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Contact us</div>
                  </v-card-title>
                  <v-card-text>Technical Support Team</v-card-text>
                  <v-list class="transparent">
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon class="blue--text text--lighten-2">mdi-phone</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>APAC +65 631 73427</v-list-item-title>
                        <v-list-item-title>EMEA +44 0 1276 608190</v-list-item-title>
                        <v-list-item-title>Americas +1 866 500 2347</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon class="blue--text text--lighten-2">mdi-email</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>technical.service@thalesgroup.com</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </section>

        <v-footer color="blue darken-2">
          <v-layout row wrap align-center>
            <v-flex xs12>
              <div class="white--text ml-4">iSeries Readers</div>
            </v-flex>
          </v-layout>
        </v-footer>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'HomePage',

  data: () => ({
    show: false,
    title: 'Logo',
    tab: null,
    readers: [
      'AT10Ki', 'AT10Ki OEM', 'CR5400i'
    ],
    ecosystem: [
      {
        text: 'vuetify-loader',
        href: 'https://github.com/vuetifyjs/vuetify-loader'
      },
      {
        text: 'github',
        href: 'https://github.com/vuetifyjs/vuetify'
      },
      {
        text: 'awesome-vuetify',
        href: 'https://github.com/vuetifyjs/awesome-vuetify'
      }
    ],
    importantLinks: [
      {
        text: 'Documentation',
        href: 'https://vuetifyjs.com'
      },
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com'
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify'
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs'
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify'
      }
    ],
    whatsNext: [
      {
        text: 'Explore components',
        href: 'https://vuetifyjs.com/components/api-explorer'
      },
      {
        text: 'Select a layout',
        href: 'https://vuetifyjs.com/layout/pre-defined'
      },
      {
        text: 'Frequently Asked Questions',
        href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions'
      }
    ],
    apps: [
      {
        title: 'iSeries Demo App',
        subTitle: 'JavaScript Web App',
        link: 'http://demo.iseriesreader.com',
        icon: 'mdi-vuejs',
        imageSrc: require('@/assets/demo-logo.png')
      },
      // {
      //   title: 'iSeries Provisioner',
      //   subTitle: 'JavaScript Provisioning App',
      //   link: '',
      //   icon: 'mdi-vuejs',
      //   imageSrc: require('../assets/provisioner-logo.png')
      // },
      {
        title: 'DocReaderEx',
        subTitle: 'iOS Demo App',
        link: 'https://apps.apple.com/us/app/docreaderex/id1427600627',
        icon: 'mdi-apple',
        imageSrc: require('@/assets/demo-logo.png')
      },
      {
        title: 'iSeries Provisioner',
        subTitle: 'iOS Provisioning App',
        link: 'https://apps.apple.com/us/app/id1504106478/',
        icon: 'mdi-apple',
        imageSrc: require('@/assets/provisioner-logo.png')
      },
      {
        title: 'iSeries Demo',
        subTitle: 'Android Demo App',
        link: 'https://play.google.com/store/apps/details?id=com.gemalto.docreader.demo',
        icon: 'mdi-android',
        imageSrc: require('@/assets/demo-logo.png')
      },
      {
        title: 'iSeries Provisioner',
        subTitle: 'Android Provisioning App',
        link: 'https://play.google.com/store/apps/details?id=com.gemalto.provisioner.iSeriesProvisioner',
        icon: 'mdi-android',
        imageSrc: require('@/assets/provisioner-logo.png')
      }
    ]
  })
}
</script>

<style scoped>
.transparent {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  margin: auto;
}
.a-class {
  width: 100%;
  height: 100%;
}
.center-span {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
