<template>
  <div class="Home">

    <v-main>
      <HomePage/>
    </v-main>

  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue'

export default {
  name: 'Home',
  components: {
    HomePage
  }
}
</script>
